/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:50fb28be-b4fa-4bcc-bfef-2dd50ad68122",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_UAXXrOPL0",
    "aws_user_pools_web_client_id": "1m18jqhb6l4materfqpqtib29n",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "anatomist215812-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
