<template>
<div>
 <div id="wel" v-if="authState !== 'signedin'">
    <h1>Welcome to Anatomist.ai</h1>
    <img id="logo" alt="anatomist logo" src="./assets/logo.png">
  </div>
  <amplify-authenticator>
   <div>
        <h2>Welcome to Anatomist.ai</h2>
        <img id="logo" alt="anatomist logo" src="./assets/logo.png">
        <p> The public version for this site is not yet available. We will contact you when we release it.</p>
    </div>
   
 <!--   <div id="s3test">
      

      <input
        type="file" multiple
        @change="onChangePut"
      />

      <div v-if="fileCount !==undefined">
          {{fileCurrent}}/{{fileCount}}
      </div>
      <div v-if="fileList.length > 0">
          <ul id="example-1">
            <li v-for="file in fileList" v-bind:key="file.key">
              <amplify-s3-image 
          :img-key="file.key"
          level="private"
          />
            </li>
          </ul>
      </div>

             
      
    </div>
   -->
    <div id="signout">
      <amplify-sign-out></amplify-sign-out>
    </div>
  </amplify-authenticator>
</div>
 
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import {Storage} from 'aws-amplify'

export default {
  name: 'App',
  components: {
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    }),
    this.fileLister(),
    this.fileLinker()
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      fileCount:undefined,
      fileCurrent:undefined,
      fileList:0,
      fileLinks: []
    }
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  methods: {
    async onChangePut(e) {
      this.fileCurrent = 0;
      var file;
      for(file of e.target.files){
        console.log(file);
        this.fileCount = e.target.files.length
        try {
          var putted = await Storage.put(file.name, file, {
            level: 'private',
            contentType: 'image/jpeg' // contentType is optional
          });
          this.fileCurrent += 1;
          console.log(putted);
        } catch (error) {
          console.log('Error uploading file: ', error);
        } 
      }
      this.fileLister()
    },

    fileLister: function (){
      Storage.list('', { level: 'private' })
          .then(result => {
            this.fileList = result
            console.log(this.fileList)
            })
          .catch(err => console.log(err));
    },
    fileLinker: async function (){
      this.fileLinks = await Storage.get(this.fileList[0], {level: "private"})
      console.log(this.fileLinks)
    }

  }
}




</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

:root {
  --amplify-primary-color: #ff822e;
  --amplify-primary-tint: #ff822e;
  --amplify-primary-shade: #ff822e;
}

#wel {
  position: absolute; /* Reposition logo from the natural layout */
  left: 25vw;
  top: 0px;
  width: 50vw;
}
#logo {
  position: relative; /* Reposition logo from the natural layout */
  max-height: 10em;
  min-height: 3em;
}

#signout {
  position: fixed;
  width: 4vw;
  top: 0;
  right: 4vw;
}

</style>
